import React, { ComponentType, lazy, Suspense } from "react";

const Loader = (Component: ComponentType) => (props: object) => (
  <Suspense fallback={<div></div>}>
    <Component {...props} />
  </Suspense>
);

const LoginPage = Loader(lazy(() => import("../pages/LoginPage")));
const PortalPage = Loader(lazy(() => import("../pages/PortalPage")));

const routes = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/register",
    element: <LoginPage />,
  },
  {
    path: "/reset-password",
    element: <LoginPage />,
  },
  {
    path: "/",
    element: <PortalPage />,
  },
];

export default routes;
