import { useRoutes } from "react-router-dom";
import React from "react";
import routes from "./router/router";

const App = () => {
  const content = useRoutes(routes);

  return <>{content}</>;
};
export default App;
